export const blockquote = [
    {
        id: 0,
        name: "Faturamento e caixa"
    },
    {
        id: 1,
        name: "Clientes"
    },
    {
        id: 2,
        name: "Contas a pagar e custos"
    },
    {
        id: 3,
        name: "Patrimônio e Estoque"
    },
    {
        id: 4,
        name: "Gerais"
    }
]