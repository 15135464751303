export const heritage = [
    {
        id: 0,
        name: "Composição dos materiais de escritório",
        description: "Relatório geral com as informações de todas as contas pagas e a pagar do estabelecimento."
    },
    {
        id: 1,
        name: "Composição do patrimônio",
        description: "Relatório com a consolidação das informações dos custos do estabelecimento."
    }
]